.carousel {
  height: calc(100% - 24px) !important;
  width: 100% !important;
}

div.slick-list {
  height: 100% !important;
}

div.slick-track {
  display: flex !important;
  height: 100% !important;
}

div.slick-slide {
  height: 100% !important;
}

div.slick-slide > div {
  height: 100% !important;
}

div.slick-slide > div > div {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-content: flex-end !important;
}

.phone-number-input {
  max-width: 100%;
}