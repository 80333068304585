.scrollContainer::-webkit-scrollbar {
    width: 0;  /* for horizontal scrollbars */
    height: 0; /* for vertical scrollbars */
}

.noScrollbar::-webkit-scrollbar {
    display: none;
}

.mapWrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
}

.map {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.map > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    text-align: center;
    z-index: 2;
    border-radius: 8px;
}

.recenterButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    z-index: 1;
}